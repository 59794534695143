'use strict';

var processInclude = require('base/util');

$(document).ready(function () {
  var writeOnlyArray = $('.grid-header').data('write-only-array');
  if (!writeOnlyArray) {
    var persistentWishlist = require('core/product/persistentWishlist');
    persistentWishlist.markProductArrWishlisted();
  }
  processInclude(window.productWishListHeart);
  processInclude(window.productWishList); // this is for the quickview heart functionality
  processInclude(require('core/product/quickView'));
  processInclude(require('./product/base'));
});
